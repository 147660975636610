<template>
  <div>
    <hs-group v-if="!hideNameInput">
      <label class="font-weight-bold">
        {{ $t(`components.media-input.components.${type}-input.components.name-input.name-group.label`) }}
      </label>
      <hs-input
        :placeholder="
          $t(`components.media-input.components.${type}-input.components.name-input.name-group.input-placeholder`)
        "
        v-model="name"
        @blur="$v.name.$touch()"
        :state="$v.name.$error ? false : null"
      />
      <hs-form-invalid-feedback :state="!$v.name.$error">
        <span class="font-size-xs" v-if="!$v.name.required">
          {{
            $t(
              `components.media-input.components.${type}-input.components.name-input.name-group.invalid-form-feedback.required`
            )
          }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>
    <component :is="previewComponent" :mimeType="mimeType" :fileURL="fileURL" />

    <slot name="extra" />

    <div class="d-flex flex-column flex-md-row justify-content-end mt-3">
      <hs-button variant="secondary" @click="$emit(`cancel`)">
        {{ $t(`actions.cancel-and-back`) }}
      </hs-button>
      <hs-button class="ml-md-3 mt-3 mt-md-0" variant="primary" @click="$emit(`save`, name)" :disabled="$v.$invalid">
        {{ $t(`components.media-input.components.${type}-input.components.name-input.save-button-label`) }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import PDFPreview from './PDFPreview.vue';
import ExcelPreview from './ExcelPreview.vue';

export default {
  name: 'NameInput',
  components: {
    PDFPreview,
    ExcelPreview,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      required: true,
    },
    fileURL: {
      type: String,
      required: true,
    },
    hideNameInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: null,
    };
  },
  validations() {
    return {
      name: !this.hideNameInput ? { required } : {},
    };
  },
  computed: {
    previewComponent() {
      return (
        {
          'application/pdf': PDFPreview,
        }[this.mimeType] || ExcelPreview
      );
    },
  },
};
</script>

<style lang="scss">
#file-input-preview {
  #header {
    background: $grey-10;
  }
}
</style>
