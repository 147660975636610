<template>
  <div>
    <hs-group>
      <label class="font-weight-bold">
        {{ $t('components.media-input.components.audio-input.components.name-input.name-group.label') }}
      </label>
      <hs-input
        :placeholder="
          $t('components.media-input.components.audio-input.components.name-input.name-group.input-placeholder')
        "
        v-model="name"
        @blur="$v.name.$touch()"
        :state="$v.name.$error ? false : null"
      />
      <hs-form-invalid-feedback :state="!$v.name.$error">
        <span class="font-size-xs" v-if="!$v.name.required">
          {{
            $t(
              'components.media-input.components.audio-input.components.name-input.name-group.invalid-form-feedback.required'
            )
          }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>
    <div id="audio-preview">
      <p id="header" class="font-size-xs p-2 overflow-auto">{{ audioURL }}</p>
      <div id="icon-container" class="d-flex justify-content-center align-items-center">
        <hs-icon icon="microphone" size="35px" />
      </div>
      <audio controls class="w-100">
        <source :src="audioURL" type="audio/mpeg" />
      </audio>
    </div>

    <slot name="extra" />

    <div class="d-flex flex-column flex-md-row justify-content-end mt-3">
      <hs-button variant="secondary" @click="$emit('cancel')">
        {{ $t('actions.cancel-and-back') }}
      </hs-button>
      <hs-button class="ml-md-3 mt-3 mt-md-0" variant="primary" @click="$emit('save', name)" :disabled="$v.$invalid">
        {{ $t('components.media-input.components.audio-input.components.name-input.save-button-label') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'NameInput',
  props: {
    audioURL: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: null,
    };
  },
  validations: {
    name: {
      required,
    },
  },
};
</script>
<style lang="scss" scoped>
#audio-preview {
  background: $grey-25;
  #header {
    background: $grey-10;
  }
  #icon-container {
    height: 220px;
  }
  audio {
    background: $grey-10;
    margin-bottom: -6px;
  }
}
</style>
