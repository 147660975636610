<template>
  <div>
    <div v-if="mode === 'input'">
      <hs-group>
        <MInput
          id="webinar-title"
          :value="title"
          :label="$t('components.media-input.components.outro-input.name-group.label')"
          :label-weight="700"
          :has-error="$v.title.$error"
          :placeholder="$t('components.media-input.components.outro-input.name-group.input-placeholder')"
          @input="title = $event"
          @blur="$v.title.$touch()"
        />
        <hs-form-invalid-feedback :state="!$v.title.$error">
          <span class="font-size-xs" v-if="!$v.title.required">
            {{ $t('components.media-input.components.outro-input.name-group.invalid-form-feedback.required') }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>
      <hs-group class="mt-3">
        <MInput
          id="webinar-url"
          :value="inputedURL"
          :label="$t('components.media-input.components.outro-input.url-group.label')"
          :label-weight="700"
          :has-error="$v.inputedURL.$error"
          :placeholder="$t('components.media-input.components.outro-input.url-group.input-placeholder')"
          @input="inputedURL = $event"
          @blur="$v.inputedURL.$touch()"
        />
        <hs-form-invalid-feedback :state="!$v.inputedURL.$error">
          <span class="font-size-xs" v-if="!$v.inputedURL.required">
            {{ $t('components.media-input.components.outro-input.url-group.invalid-form-feedback.required') }}
          </span>
          <span class="font-size-xs" v-if="!$v.inputedURL.url">
            {{ $t('components.media-input.components.outro-input.url-group.invalid-form-feedback.url') }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>
    </div>
    <div v-else>
      <b-embed type="iframe" acpect="16by9" :src="reproductionURL" allowFullscreen />
      <slot name="extra" />
    </div>
    <hs-group v-if="type === 'outro'">
      <MInput
        :placeholder="$t('components.media-input.components.outro-input.name-group.input-chat-placeholder')"
        :label="$t('components.media-input.components.outro-input.name-group.label-chat')"
        :label-weight="700"
        :state="$v.title.$error ? false : null"
        v-model="chat_url"
        @blur="$v.title.$touch()"
      />
    </hs-group>
    <div class="d-flex flex-column flex-md-row justify-content-end mt-3">
      <MXButton variant="secondary" @click="$emit('cancel')"> {{ $t('actions.cancel-and-back') }} </MXButton>

      <MXButton class="ml-md-3 mt-3 mt-md-0" @click="primaryButton.onClick" variant="primary" :disabled="$v.$invalid">
        {{ primaryButton.label }}
      </MXButton>
    </div>
  </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import { extractVideoKey } from '@/libs/video_url.js';
import MXButton from '@/shared/components/MXButton.vue';
import MInput from '@/components/MInput.vue';

export default {
  name: 'webinarHerospark',
  components: {
    MInput,
    MXButton,
  },
  data() {
    return {
      mode: 'input',
      inputedURL: null,
      reproductionURL: null,
      id: null,
      title: null,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    primaryButton() {
      if (this.mode === 'input') {
        return {
          label: this.$t('components.media-input.components.outro-input.load-button-label'),
          onClick: this.onLoad,
        };
      }
      return {
        label: this.$t('components.media-input.components.outro-input.save-button-label'),
        onClick: this.onSave,
      };
    },
  },
  methods: {
    onLoad() {
      this.id = extractVideoKey(this.inputedURl);
      this.reproductionURL = `//youtube.com/embed/${this.id}`;
      this.mode = 'preview';
    },

    onSave() {
      this.$emit('save', {
        type: 'WebinarHerospark',
        origin_url: this.inputedURL,
        title: this.title,
        host_key: this.id,
      });
    },
  },
  validations: {
    title: {
      required,
    },
    inputedURL: {
      required,
      url,
    },
  },
};
</script>
