<template>
  <div>
    <hs-file-drop-selector
      v-if="mode === 'file-selection'"
      acceptedFormats="audio/*"
      height="336px"
      @onFile="onFile"
      @cancel="$emit('cancel')"
    >
      <template #extra>
        <slot name="extra" />
      </template>
    </hs-file-drop-selector>
    <div
      v-if="mode === 'file-uploading'"
      id="radial-progress-container"
      class="m-md-6 d-flex justify-content-center align-items-center"
    >
      <hs-radial-progress :progress="uploadProgress" />
    </div>
    <NameInput v-if="mode === 'name-input'" :audioURL="audioURL" @save="onNameInputSave" @cancel="$emit('cancel')">
      <template #extra>
        <slot name="extra" />
      </template>
    </NameInput>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import uploadService from '@/services/upload';
import toastHelper from '@/shared/helpers/toast';
import NameInput from './components/NameInput';
import { hsFileDropSelector, hsRadialProgress } from '@/components';

export default {
  name: 'AudioInput',
  data() {
    return {
      mode: 'file-selection',
      uploadProgress: 0,
      audioURL: null,
      name: null,
    };
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
  },
  methods: {
    async onFile(file) {
      if (file.type.indexOf('audio') !== -1) {
        this.mode = 'file-uploading';
        try {
          const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
          this.audioURL = await uploadService.upload(data.url, data.fields, file, progressEvent => {
            this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          });
          this.mode = 'name-input';
        } catch (error) {
          toastHelper.dangerMessage(
            this.$t('components.media-input.components.audio-input.index.upload-error-message')
          );
          this.mode = 'file-selection';
        }
      } else {
        toastHelper.dangerMessage(
          this.$t('components.media-input.components.audio-input.index.audio-type-required-message')
        );
      }
    },
    onNameInputSave(name) {
      this.$emit('save', {
        type: 'Audio',
        title: name,
        s3_file_url: this.audioURL,
      });
    },
  },
  components: {
    hsFileDropSelector,
    hsRadialProgress,
    NameInput,
  },
};
</script>
<style lang="scss" scoped>
#radial-progress-container {
  height: 336px;
  background: $grey-10;
}
</style>
