<template>
  <div>
    <hs-file-drop-selector
      v-if="mode === 'file-selection'"
      :type="type"
      :supportedFiles="configuration.supportedFiles"
      :label1="$t(`components.file-drop-selector.${type}.label-1`)"
      height="336px"
      @onFile="onFile"
      @cancel="$emit('cancel')"
    >
      <template #extra>
        <slot name="extra" />
      </template>

      <template v-slot:footer="">
        <div class="pill">
          {{ $t(`components.file-drop-selector.${type}.format`) }}
        </div>
      </template>
    </hs-file-drop-selector>
    <div
      v-if="mode === 'file-uploading'"
      id="radial-progress-container"
      class="m-md-6 d-flex justify-content-center align-items-center"
    >
      <hs-radial-progress :progress="uploadProgress" />
    </div>
    <NameInput
      v-if="mode === 'name-input'"
      :type="type"
      :hideNameInput="$attrs.hideNameInput"
      :mimeType="mimeType"
      :fileURL="fileURL"
      @save="onNameInputSave"
      @cancel="$emit('cancel')"
    >
      <template #extra>
        <slot name="extra" />
      </template>
    </NameInput>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import uploadService from '@/services/upload';
import toastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import NameInput from './components/NameInput';
import { hsFileDropSelector, hsRadialProgress } from '@/components';

export default {
  name: 'FileInput',
  props: {
    type: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
    },
    hideNameInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'file-selection',
      uploadProgress: 0,
      mimeType: null,
      fileURL: null,
      name: null,
    };
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
  },
  methods: {
    async onFile(file) {
      const { supportedFiles } = this.configuration;
      const mimeType = file.type;
      const isSupported = GeneralHelper.isSupportedFile({
        supportedFiles,
        fileName: file.name,
        fileType: mimeType,
      });
      if (!isSupported) {
        toastHelper.dangerMessage(
          this.$t(`components.media-input.components.${this.type}-input.index.upload-error-only-formats`)
        );
        return;
      }

      this.mode = 'file-uploading';
      try {
        const { data } = await uploadService.getAwsKeys(mimeType, this.schoolId);
        this.fileURL = await uploadService.upload(data.url, data.fields, file, progressEvent => {
          this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        });
        this.mode = 'name-input';
        this.mimeType = mimeType;
      } catch (error) {
        toastHelper.dangerMessage(
          this.$t(`components.media-input.components.${this.type}-input.index.upload-error-message`)
        );
        this.mode = 'file-selection';
      }
    },
    onNameInputSave(name) {
      this.$emit('save', {
        type: 'Document',
        title: name,
        s3_file_url: this.fileURL,
      });
    },
  },
  components: {
    hsFileDropSelector,
    hsRadialProgress,
    NameInput,
  },
};
</script>
<style lang="scss" scoped>
#radial-progress-container {
  height: 336px;
  background: $grey-10;
}
.pill {
  font-size: 0.8rem;
  padding: 4px 14px;
  border-radius: 20px;
  border: 1px solid $grey-25;
  color: $grey-25;
}
</style>
