export function detectHttpProtocol(urlstring) {
  return (/https?:\/\//.exec(urlstring) ? '' : 'https://') + urlstring;
}
export function fromPath(x) {
  return (/\/(video\/|embed\/|v\/)([A-Za-z0-9._%-]*)/.exec(x) || [])[2];
}
export function extractVideoKey(urlstring) {
  try {
    const url = new URL(detectHttpProtocol(urlstring));
    return url.searchParams.get('v') || fromPath(url.pathname);
  } catch (e) {
    return null;
  }
}
