<template>
  <component :is="component" v-bind="[$attrs, $props]" v-on="$listeners">
    <template #extra>
      <slot name="extra" />
    </template>
  </component>
</template>

<script>
import YoutubeInput from './components/YoutubeInput';
import SparkVideosInput from './components/SparkVideosInput';
import VimeoInput from './components/VimeoInput';
import AudioInput from './components/AudioInput';
import FileInput from './components/FileInput';
import TextInput from './components/TextInput';
import ImageInput from './components/ImageInput';
import WebinarInput from './components/WebinarInput';

export default {
  name: 'MediaInput',
  props: {
    type: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
    },
    hideNameInput: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component() {
      const table = {
        youtube: YoutubeInput,
        sparkVideos: SparkVideosInput,
        vimeo: VimeoInput,
        audio: AudioInput,
        ebook: FileInput,
        file: FileInput,
        text: TextInput,
        image: ImageInput,
        outro: WebinarInput,
        WebinarHerospark: WebinarInput,
      };

      return table[this.type];
    },
  },
  components: {
    YoutubeInput,
    SparkVideosInput,
    VimeoInput,
    AudioInput,
    FileInput,
    TextInput,
    ImageInput,
    WebinarInput,
  },
};
</script>
