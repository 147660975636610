<template>
  <div>
    <hs-group class="font-weight-bold" :label="$t('components.media-input.components.text-input.name-group.label')">
      <MInput
        :placeholder="$t('components.media-input.components.text-input.name-group.input-placeholder')"
        :state="$v.name.$error ? false : null"
        v-model="name"
        @blur="$v.name.$touch()"
      />
      <hs-form-invalid-feedback :state="!$v.name.$error">
        <span class="font-size-xs" v-if="!$v.name.required">
          {{ $t('components.media-input.components.text-input.name-group.invalid-form-feedback.required') }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>

    <Editor v-model="text" :initial-value="text || ''" :init="editorDefaultInit" />

    <slot name="extra" />

    <div class="d-flex justify-content-end mt-4">
      <MXButton variant="secondary" @click="$emit('cancel')">
        {{ $t('actions.cancel-and-back') }}
      </MXButton>

      <MXButton class="ml-3" variant="primary" @click="onSave" :disabled="$v.$invalid">
        {{ $t('components.media-input.components.text-input.save-button-label') }}
      </MXButton>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Editor from '@tinymce/tinymce-vue';
import { editorDefaultInit } from '@/libs/tinymce';
import MInput from '@/components/MInput.vue';
import MXButton from '@/shared/components/MXButton.vue';

export default {
  name: 'TextInput',
  data() {
    return {
      name: null,
      text: null,
      editorDefaultInit: { ...editorDefaultInit },
    };
  },
  components: {
    Editor,
    MInput,
    MXButton,
  },
  mounted() {
    this.name = this.$attrs.media.title || null;
    this.text = this.$attrs.media.text || null;
  },
  methods: {
    onSave() {
      this.$emit('save', {
        type: 'Text',
        title: this.name,
        text: this.text,
      });
    },
  },
  validations: {
    name: {
      required,
    },
  },
};
</script>
