<template>
  <div>
    <hs-group>
      <label class="font-weight-bold">
        {{ $t('components.media-input.components.spark-videos-input.components.name-input.name-group.label') }}
      </label>
      <hs-input
        :placeholder="
          $t('components.media-input.components.spark-videos-input.components.name-input.name-group.input-placeholder')
        "
        v-model="name"
        @blur="$v.name.$touch()"
        :state="$v.name.$error ? false : null"
      />
      <hs-form-invalid-feedback :state="!$v.name.$error">
        <span class="font-size-xs" v-if="!$v.name.required">
          {{
            $t(
              'components.media-input.components.spark-videos-input.components.name-input.name-group.invalid-form-feedback.required'
            )
          }}
        </span>
      </hs-form-invalid-feedback>
    </hs-group>
    <div id="preview">
      <template v-if="isFormatSupported">
        <div>
          <p id="header" class="font-size-xs p-2 overflow-auto">{{ videoURL }}</p>
          <video ref="videoRef" :src="videoURL" width="100%" controls></video>
        </div>
      </template>
      <div v-else class="bg-warning rounded-lg p-3">
        {{ $t('components.media-input.components.spark-videos-input.components.name-input.unsupported-video-preview') }}
      </div>
    </div>

    <slot name="extra" />

    <div class="d-flex flex-column flex-md-row justify-content-end mt-3">
      <MXButton variant="secondary" @click="$emit('cancel')">
        {{ $t('components.media-input.components.spark-videos-input.components.name-input.cancel-button-label') }}
      </MXButton>
      <MXButton class="ml-md-3 mt-3 mt-md-0" variant="primary" @click="$emit('save', name)" :disabled="$v.$invalid">
        {{ $t('components.media-input.components.spark-videos-input.components.name-input.save-button-label') }}
      </MXButton>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import MXButton from '@/shared/components/MXButton.vue';
export default {
  name: 'NameInput',
  components: {
    MXButton,
  },
  props: {
    videoURL: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: null,
      isFormatSupported: true,
    };
  },
  validations: {
    name: {
      required,
    },
  },
  mounted() {
    this.$refs.videoRef.addEventListener('error', () => (this.isFormatSupported = false));
  },
};
</script>
<style lang="scss" scoped>
#preview {
  #header {
    background: $grey-10;
  }
}
</style>
