<template>
  <div>
    <hs-file-drop-selector
      v-if="mode === 'file-selection'"
      height="336px"
      :label1="$t('components.file-drop-selector.sparkvideos.label-1')"
      @onFile="onFile"
      @cancel="$emit('cancel')"
    />
    <div
      v-if="mode === 'file-uploading'"
      id="radial-progress-container"
      class="m-md-6 d-flex justify-content-center align-items-center"
    >
      <hs-radial-progress :progress="uploadProgress" />
    </div>

    <NameInput v-if="mode === 'name-input'" :videoURL="videoURL" @save="onNameInputSave" @cancel="$emit('cancel')">
      <template #extra>
        <slot name="extra" />
      </template>
    </NameInput>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { Uploader } from '@edools/sparkvideos-uploader';
import uploadService from '@/services/upload';
import toastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import NameInput from './components/NameInput';
import { hsFileDropSelector, hsRadialProgress } from '@/components';

const MAX_UPLOAD_SIZE = Math.pow(1024, 3) * 15; // 10GB
const SIZE_SWITCH_MULTIUPLOAD = 0;

export default {
  name: 'SparkVideosInput',
  data() {
    return {
      mode: 'file-selection',
      uploadProgress: 0,
      videoURL: null,
    };
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    ...mapState('auth', {
      jwt: state => state.loggedUser.jwt,
    }),
  },
  methods: {
    async onFile(file) {
      if (!GeneralHelper.isSupportedFile({ supportedFiles: ['video/', '.mkv', '.flv'], fileType: file.type })) {
        toastHelper.dangerMessage(
          this.$t('components.media-input.components.spark-videos-input.index.upload-error-formats')
        );
        return;
      }

      this.mode = 'file-uploading';

      try {
        if (file.size > SIZE_SWITCH_MULTIUPLOAD) await this.multiUpload(file);
        else await this.simpleUpload(file);
      } catch (_error) {
        this.uploadErroHandler();
      }
    },
    uploadErroHandler() {
      toastHelper.dangerMessage(
        this.$t('components.media-input.components.spark-videos-input.index.upload-error-message')
      );
      this.mode = 'file-selection';
    },
    async multiUpload(file) {
      if (file.size > MAX_UPLOAD_SIZE) {
        toastHelper.dangerMessage(
          this.$t('components.media-input.components.spark-videos-input.index.upload-max-size-error-message')
        );

        this.mode = 'file-selection';
        return;
      }
      const uploader = new Uploader({
        token: this.jwt,
        baseURL: process.env.VUE_APP_SPARKVIDEOS_MULTIUPLOAD_URL,
        onError: () => this.uploadErroHandler(),
        onProgress: progress => ((this.uploadProgress = progress.percentage), this.$nextTick()),
        onSuccess: url => {
          this.videoURL = url;
          this.mode = 'name-input';
        },
      });
      await uploader.upload(file);
    },
    async simpleUpload(file) {
      const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
      this.videoURL = await uploadService.upload(data.url, data.fields, file, progressEvent => {
        this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      });
      this.mode = 'name-input';
    },
    onNameInputSave(title) {
      this.$emit('save', {
        type: 'Video',
        host: 'SparkVideos',
        title,
        s3_file_url: this.videoURL,
      });
    },
  },
  components: {
    hsFileDropSelector,
    hsRadialProgress,
    NameInput,
  },
};
</script>
<style lang="scss" scoped>
#radial-progress-container {
  height: 336px;
  background: $grey-10;
}
</style>
