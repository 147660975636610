<template>
  <div class="m-input">
    <label :for="id">
      <span v-if="label" class="m-input-label" :style="{ fontWeight: labelWeight }">{{ label }}</span>

      <div
        class="m-input-wrapper"
        :class="{
          'm-input-wrapper--disabled': $attrs.disabled,
          'm-input-error': hasError,
        }"
      >
        <hs-icon v-if="icon" class="m-input-icon" :variant="iconVariant" :icon="icon" />
        <input
          :id="id"
          v-model="localValue"
          v-bind="$attrs"
          :maxlength="maxLength"
          @input="updateValue"
          @blur="onBlur"
        />
      </div>
    </label>
    <span v-if="maxLength && displayCounter" class="m-input__char-counter">
      {{ localValue.length }}/{{ maxLength }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'MInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    labelWeight: {
      type: String,
      default: '400',
    },
    value: {
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    iconVariant: {
      type: String,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    displayCounter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
  methods: {
    updateValue(event) {
      this.localValue = event.target.value;
      this.$emit('input', this.localValue);
    },
    onBlur() {
      this.$emit('blur');
    },
  },
};
</script>

<style scoped lang="scss">
.m-input {
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .m-input-label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: v-bind(labelWeight);
    line-height: 21px;
  }

  .m-input-wrapper {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 16px;

    display: flex;
    align-items: center;

    &--disabled {
      background-color: #e3e3e3;
      cursor: not-allowed;
    }

    &:focus-within {
      outline: 1px solid #3575d4;
      outline-offset: 1px;
    }

    &.m-input-error {
      border-color: #db3939;
    }

    .m-input-icon {
      margin-right: 8px;
    }

    input {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      &:focus {
        outline: none !important;
      }

      &::placeholder {
        color: #6f6f6f;
      }

      &:disabled {
        background-color: #e3e3e3;
        cursor: not-allowed;
      }
    }
  }
}

.m-input__char-counter {
  font-size: 12px;
  color: #262626;
  float: right;
}
</style>
