<template>
  <div>
    <div v-if="mode === 'input'">
      <hs-group>
        <label class="font-weight-bold">
          {{ $t('components.media-input.components.youtube-input.name-group.label') }}
        </label>

        <MInput
          :placeholder="$t('components.media-input.components.youtube-input.name-group.input-placeholder')"
          :state="$v.title.$error ? false : null"
          v-model="title"
          @blur="$v.title.$touch()"
        />

        <hs-form-invalid-feedback :state="!$v.title.$error">
          <span class="font-size-xs" v-if="!$v.title.required">
            {{ $t('components.media-input.components.youtube-input.name-group.invalid-form-feedback.required') }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>

      <hs-group class="mt-3">
        <label class="font-weight-bold">
          {{ $t('components.media-input.components.youtube-input.url-group.label') }}
        </label>

        <MInput
          :placeholder="$t('components.media-input.components.youtube-input.url-group.input-placeholder')"
          :state="$v.inputedURL.$error ? false : null"
          v-model="inputedURL"
          @blur="$v.inputedURL.$touch()"
        />

        <hs-form-invalid-feedback :state="!$v.inputedURL.$error">
          <span class="font-size-xs" v-if="!$v.inputedURL.required">
            {{ $t('components.media-input.components.youtube-input.url-group.invalid-form-feedback.required') }}
          </span>
          <span class="font-size-xs" v-if="!$v.inputedURL.url">
            {{ $t('components.media-input.components.youtube-input.url-group.invalid-form-feedback.url') }}
          </span>
        </hs-form-invalid-feedback>
      </hs-group>
    </div>

    <div v-else>
      <b-embed type="iframe" acpect="16by9" :src="reproductionURL" allowFullscreen />
      <slot name="extra" />
    </div>

    <div class="d-flex flex-column flex-md-row justify-content-end mt-3">
      <MXButton variant="secondary" @click="$emit('cancel')">
        {{ $t('actions.cancel-and-back') }}
      </MXButton>

      <MXButton class="ml-md-3 mt-3 mt-md-0" @click="primaryButton.onClick" variant="primary" :disabled="$v.$invalid">
        {{ primaryButton.label }}
      </MXButton>
    </div>
  </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import MXButton from '@/shared/components/MXButton.vue';
import MInput from '@/components/MInput.vue';

export default {
  name: 'YoutubeInput',
  components: {
    MXButton,
    MInput,
  },
  data() {
    return {
      mode: 'input',
      inputedURL: null,
      reproductionURL: null,
      id: null,
      title: null,
    };
  },
  computed: {
    primaryButton() {
      if (this.mode === 'input') {
        return {
          label: this.$t('components.media-input.components.youtube-input.load-button-label'),
          onClick: this.onLoad,
        };
      }
      return {
        label: this.$t('components.media-input.components.youtube-input.save-button-label'),
        onClick: this.onSave,
      };
    },
  },
  methods: {
    onLoad() {
      this.id = this.inputedURL.match(
        /(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\\&\S+)?/
      )[6];
      this.reproductionURL = `//youtube.com/embed/${this.id}`;
      this.mode = 'preview';
    },
    onSave() {
      this.$emit('save', {
        type: 'Video',
        host: 'Youtube',
        s3_file_url: this.inputedURL,
        title: this.title,
        host_key: this.id,
      });
    },
  },
  validations: {
    title: {
      required,
    },
    inputedURL: {
      required,
      url,
    },
  },
};
</script>
